<template>
  <a-modal title="新增" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px" centered
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
                <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio value="1">
                        模块
                    </a-radio>
                    <a-radio value="2">
                        控制器
                    </a-radio>
                    <a-radio value="3">
                        操作
                    </a-radio>
                </a-radio-group >
                <a-radio-group v-else-if="item.type === 'switch'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio value="1">
                        启用
                    </a-radio>
                    <a-radio value="0">
                        禁用
                    </a-radio>
                </a-radio-group >
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'title',
        label: '权限名称',
        type: 'input',
        rules: [{ required: true, message: '请输入权限名称!' }],
    },
    {
        name: 'name',
        label: '权限定义',
        type: 'input',
        rules: [{ required: true, message: '请输入权限定义!' }],
    },
    {
        name: 'level',
        label: '权限级别',
        type: 'radio',
        rules: [],
    },
    {
        name: 'status',
        label: '权限状态',
        type: 'switch',
        rules: [],
    },
]
    export default {
        name:'editModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                status:true,
                confirmLoading:false,
                form: this.$form.createForm(this),
            }
        },
        props:['roleItem'],
        async created() {
            this.visible = true
        },
        mounted () {
            this.form.setFieldsValue({level:'1'})
            this.form.setFieldsValue({status:'1'})
        },
        beforeDestroy() {
            this.visible = false
        },
        methods: {
            async handleOk() {
                let that = this
                try {
                    let data = await this.form.validateFields()
                    this.confirmLoading=true;
                    if(this.status){
                        data.status = 1
                    }else{
                        data.status = 0
                    }
                    data.pid = this.roleItem.id
                    data.types = this.roleItem.types
                    await this.$store.dispatch('enterpriseRuleAddAction',{data:data})
                        .then(res=>{
                            this.$message.success('操作成功！')
                            this.parent.hideEditModal(1)
                        })
                        .catch(err=>{
                            this.confirmLoading=false;
                        })
                } catch {
                }
            },
            handleCancel() {
                this.parent.hideEditModal(0)
            },
        },
    }
</script>
