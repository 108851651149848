<template>
    <div class="zcxy zl-list">
        <div class="title flts-title">
            <h3>角色权限</h3>
        </div>
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
                :columns="columns" :dataSource="list" :expandedRowKeys='expandedList' @expand="onExpand">
                    <template slot="roleTitle" slot-scope="text,record">
                        <span>
                            <a-input
                            v-if="record.editable"
                            style="margin: -5px 0;width:200px;"
                            :value="text"
                            @change="e => handleChangeTitle(e.target.value,record)"
                            />
                            <template v-else>{{ text }}</template>
                        </span>
                    </template>
                    <template slot="name" slot-scope="text,record">
                        <div>
                            <a-input
                            v-if="record.editable"
                            style="margin: -5px 0;width:200px;"
                            :value="text"
                            @change="e => handleChangeName(e.target.value,record)"
                            />
                            <template v-else>{{ text }}</template>
                        </div>
                    </template>
                    <template slot="radio" slot-scope="text,record">
                        <a-radio-group v-if="record.editable" name="radioGroup" :value="text" @change="e => handleRadio(e.target.value,record)">
                            <a-radio value="1">
                                模块
                            </a-radio>
                            <a-radio value="2">
                                控制器
                            </a-radio>
                            <a-radio value="3">
                                操作
                            </a-radio>
                        </a-radio-group>
                        <a-radio-group v-else name="radioGroup" :value="text">
                            <a-radio value="1">
                                模块
                            </a-radio>
                            <a-radio value="2">
                                控制器
                            </a-radio>
                            <a-radio value="3">
                                操作
                            </a-radio>
                        </a-radio-group>
                    </template>
                    <template slot="status" slot-scope="text,record">


                        <a-radio-group v-if="record.editable" name="radioGroup" :value="text" @change="e => changeStatus(record)">
                            <a-radio value="1">
                                启用
                            </a-radio>
                            <a-radio value="0">
                                禁用
                            </a-radio>
                        </a-radio-group>
                        <a-radio-group v-else name="radioGroup" :value="text">
                            <a-radio value="1">
                                启用
                            </a-radio>
                            <a-radio value="0">
                                禁用
                            </a-radio>
                        </a-radio-group>

                        <!-- <a-switch checkedChildren="启用" v-if="record.editable"  unCheckedChildren="禁用" :checked="text === '1'" @change="changeStatus(record)"/>
                        <a-switch checkedChildren="启用 " v-if="!record.editable" unCheckedChildren="禁用 " :checked="text === '1'"/> -->
                    </template>
                    <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" v-if="!record.editable" @click="toEdit(record)">编辑</a>
                        <a href="javascript:;" v-else @click="toSave(record)">保存</a>
                        <a-divider v-if="record.level == '1' || record.level == '2'" type="vertical" />
                        <a href="javascript:;" v-if="record.level == '1' || record.level == '2'" @click="showEditModal(record)">新增</a>
                    </template>
            </a-table>
        </div>
        <editModal :roleItem='roleItem' v-if='isEditModal'/>
    </div>
</template>

<script>
const columns = [
  { title: '权限名称', dataIndex: 'title', key: '1',scopedSlots: { customRender: 'roleTitle' }},
  { title: '权限定义', dataIndex: 'name', key: '2' ,scopedSlots: { customRender: 'name' }},
  { title: '权限级别', dataIndex: 'level', key: '3' ,scopedSlots: { customRender: 'radio' }},
  { title: '权限状态', dataIndex: 'status', key: '4' ,scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation', fixed: 'right', scopedSlots: { customRender: 'action' }}
]

import editModal from './editModal'
    export default {
        name:'RoleAuth',
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                list: [],
                loading:false,
                columns,
                selectedKeys:[],
                checkedKeys:[],
                expandedList:[],
                isEditModal:false,
                roleItem:{},
            }
        },
        components:{editModal},
        props:['isTab'],
        created () {
            this.getRule();
        },
        methods: {
            async getRule() {
                let that = this
                that.expandedList = []
                this.loading = true
                let res = await this.$store.dispatch('enterpriseRuleAction', {data:{types: this.isTab}})
                let list = res.data;
                list.forEach((item)=>{
                    item.editable = false
                    that.expandedList.push(item.id)
                    item.children.forEach((it)=>{
                        that.expandedList.push(it.id)
                        it.editable = false;
                        it.children.forEach((i)=>{
                            i.editable = false
                            if(i.children.length > 0 ){
                                that.expandedList.push(i.id)
                                i.children.forEach((d)=>{
                                    d.editable = false
                                    delete d.children
                                })
                            }else{
                                delete i.children
                            }
                        })
                    })
                })
                this.list = list
                this.loading = false
            },
            handleChangeTitle(value, key, column) {
                console.log(value,key,column)
                this.list.forEach((item)=>{
                    if(item.id == key.id){
                        item.title = value
                    }
                    item.children.forEach((it)=>{
                        if(it.id == key.id){
                            it.title = value
                        }
                        it.children.forEach((i)=>{
                           if(i.id == key.id){
                               i.title = value
                           }
                           if(i.children){
                            i.children.forEach((j)=>{
                                if(j.id == key.id){
                                        j.title = value
                                    }
                            })
                           }
                        })
                    })
                })
            },
            handleChangeName(value, key, column){
                console.log(value,key,column)
                this.list.forEach((item)=>{
                    if(item.id == key.id){
                        item.name = value
                    }
                    item.children.forEach((it)=>{
                        if(it.id == key.id){
                            it.name = value
                        }
                        it.children.forEach((i)=>{
                           if(i.id == key.id){
                               i.name = value
                           }
                           if(i.children){
                            i.children.forEach((j)=>{
                                if(j.id == key.id){
                                        j.name = value
                                    }
                            })
                           }
                        })
                    })
                })
            },
            toEdit(val){
                console.log(val,this.list)
                this.list.forEach((item)=>{
                    if(item.id == val.id){
                        item.editable = true
                    }
                    item.children.forEach((it)=>{
                        if(it.id == val.id){
                            it.editable = true
                        }
                        it.children.forEach((i)=>{
                           if(i.id == val.id){
                               i.editable = true
                           }
                           if(i.children){
                            i.children.forEach((j)=>{
                                if(j.id == val.id){
                                        j.editable = true
                                    }
                            })
                           }
                        })
                    })
                })
            },
            async toSave(val){
                if(!val.title){
                    this.$message.warning('请输入名称!')
                    return false
                }
                if(!val.name){
                    this.$message.warning('请输入定义!')
                    return false
                }
                let res = await this.$store.dispatch('enterpriseUpdateAction', {data:val})

                this.$message.success('操作成功!');
                this.list.forEach((item)=>{
                    if(item.id == val.id){
                        item.editable = false
                    }
                    item.children.forEach((it)=>{
                        if(it.id == val.id){
                            it.editable = false
                        }
                        it.children.forEach((i)=>{
                           if(i.id == val.id){
                               i.editable = false
                           }
                           if(i.children){
                            i.children.forEach((j)=>{
                                if(j.id == val.id){
                                        j.editable = false
                                    }
                            })
                           }
                        })
                    })
                });
            },
            toDel(){

            },
            showEditModal(val){
                this.roleItem = val
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val == 1){
                    this.getRule()
                }
                this.isEditModal = false
            },
            onExpand(a,b){
                let list = this.expandedList;
                if(a){
                    list.push(b.id)
                }else{
                    list.splice(list.indexOf(b.id),1)
                }
                this.expandedList = list
            },
            changeStatus(val){
                let status
                if(val.status == '1'){
                    status = '0'
                }else{
                    status = '1'
                }
                this.list.forEach((item)=>{
                    if(item.id == val.id){
                        item.status = status
                    }
                    item.children.forEach((it)=>{
                        if(it.id == val.id){
                            it.status = status
                        }
                        it.children.forEach((i)=>{
                           if(i.id == val.id){
                               i.status = status
                           }
                           if(i.children){
                            i.children.forEach((j)=>{
                                if(j.id == val.id){
                                        j.status = status
                                    }
                            })
                           }
                        })
                    })
                })
            },
            handleRadio(e,val){
                this.list.forEach((item)=>{
                    if(item.id == val.id){
                        item.level = e
                    }
                    item.children.forEach((it)=>{
                        if(it.id == val.id){
                            it.level = e
                        }
                        it.children.forEach((i)=>{
                           if(i.id == val.id){
                               i.level = e
                           }
                           if(i.children){
                            i.children.forEach((j)=>{
                                if(j.id == val.id){
                                    j.level = e
                                }
                            })
                           }
                        })
                    })
                })
            }
            
        },
        watch: {
            isTab(){
                this.getRule()
            }
        },
    }
</script>

<style lang="scss">
    
</style>