<template>
    <div class="auth">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>通用设置</a-breadcrumb-item>
            <a-breadcrumb-item>权限管理</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box systemMeun">
          <div style="width:200px;max-height:800px;position:relative">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-menu :defaultSelectedKeys="[0]" @click="handleClick">
              <a-menu-item v-for="(item,index) in list" :key="index">
                <a-row>
                  <a-col :span="18">
                    {{item.name}}
                  </a-col>
                </a-row>
              </a-menu-item>
            </a-menu>
          </div>
          <div style="flex:1;padding-left:10px">
              <RoleAuth :isTab='isTab'/>
          </div>
        </div>
    </div>
</template>

<script>
import RoleAuth from './RoleAuth'
export default {
  name: 'auth',
  provide() {
    return {
      parent: this
    }
  },
  components: {
      RoleAuth,
  },
  data() {
    return {
        tabPosition: 'left',
        keyPath:[0],
        loading:false,
        list:[],
        isTab:1,
    }
  },
  async created () {
      await this.getList()
  },
  
  methods:{
    async getList(){
        this.loading = true
        let res = await this.$store.dispatch('enterpriseRuleTypesAction', {})
        this.isTab = res.data[0].type_id
        this.list = res.data
        this.loading = false
    },
    handleClick(e) {
      this.isTab = this.list[e.key].type_id
      this.keyPath = e.keyPath;
    },
  },
}
</script>
<style lang="scss">
  .auth{
    .main-box{
      .ant-tabs-tab{
        text-align: left;
      }
      .ant-menu-light{
        height: 90%;
        border-right: 2px solid #e8e8e8;
      }
      .ant-menu-item:hover{
        background-color: #e6fff6;
      }
    }
    .systemMeun{
      display: flex;
      .ant-dropdown-trigger{
        height: 100%;
      }
      .ant-popover-inner-content{
        padding:5px 0;
      }
    }
    .bizParam-box{
      display: flex;
    }
    .ant-form-item{
      margin-bottom: 12px;
    }
    .ant-menu-item-selected{
      box-shadow: 2px 0px 0px #00cca2
    }
  }
</style>